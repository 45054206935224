import React from "react"
import { graphql } from "gatsby"
import FrontLayout from "../components/FrontLayout"
import { Box, Heading, Text } from "@chakra-ui/layout"

export default function Page({ data }) {
  //highlight-line
  return (
    <FrontLayout>
      <Box bg="brand.sub" w="100%" p={4} color="white">
        <Heading>Nolvem Garage</Heading>
      </Box>
      {/* highlight-start */}
      {data.allWpPost.nodes.map(node => (
        <Box my={4}>
          <Text fontSize="xl">{node.title}</Text>
          <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
        </Box>
      ))}
      {/* highlight-end */}
    </FrontLayout>
  )
}

//highlight-start
export const pageQuery = graphql`
  query {
    allWpPost(sort: { fields: [date], order: DESC }) {
      nodes {
        title
        excerpt
        slug
      }
    }
  }
`
//highlight-end
